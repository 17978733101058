<template>
  <b-card>
    <div v-if="isLoading">
      <aom-skeleton-loader />
    </div>

    <div v-else>
      <b-row>
        <b-col
          v-for="(application, index) in applications"
          :key="index"
          sm="6"
          lg="4"
          cols="12"
          class="mb-2"
        >
          <b-card
            border-variant="primary"
            tag="article"
          >
            <b-card-text>
              <b-card-title class="text-truncate mb-1">
                {{ application.name }}
              </b-card-title>
              <p class="mb-0">
                <b-badge
                  variant="primary"
                  class="font-weight-normal"
                  style="margin-right: 2px"
                >
                  {{ application.roleName }}
                </b-badge>
              </p>
            </b-card-text>

            <b-dropdown-divider
              style="list-style-type: none"
            />

            <b-card-text class="mt-1">
              <b-button
                variant="primary"
                type="link"
                :to="application.route"
              >
                Complete
              </b-button>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BBadge,
  BButton,
  BCardText,
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BDropdownDivider,
} from "bootstrap-vue";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { makeErrorToast } from "@/libs/utils";
import applicationService from "@/services/applicationService";
import { userRoles } from "@/models";

export default {
  components: {
    BBadge,
    BButton,
    BCardText,
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BDropdownDivider,
    AomSkeletonLoader,
  },

  data() {
    return {
      isLoading: false,
      applications: []
    };
  },

  computed: {
    ...mapGetters("app", ["isParticipantPortal"]),
    ...mapGetters("participants", [
      "program",
      "mentorDisplay",
      "menteeDisplay",
    ]),
    ...mapGetters("profile", ["profile"]),

    appCurrentProgram() {
      return this.$store.getters['app/currentProgram'];
    },
    applicationSetId() {
      const program = this.$store.getters['participants/program'];
      if (program && program.application_set) {
        return program.application_set.id;
      }
      return undefined;
    },
  },
  created() {
    this.loadApplications();
  },
  methods: {
    async loadApplications() {
      try {
        this.isLoading = true;

        if (this.isParticipantPortal &&
          this.appCurrentProgram &&
          this.appCurrentProgram.id &&
          this.applicationSetId
        ) {
          const response = await applicationService.getApplicationSets(this.appCurrentProgram.id, this.applicationSetId);

          if (response && response.data) {
            this.applications = response.data.applications
              .filter(item => {
                // Filter application by user role
                const role = item.roles[0];
                return this.profile.roles.includes(role.id);
              })
              .map(item => {
                const role = item.roles[0];
                let roleName = '';
                let route = {};
                if (role.id === userRoles.MENTOR) {
                  roleName = this.mentorDisplay;
                  route = { name: 'participant-application-mentor-overview' };
                }
                if (role.id === userRoles.MENTEE) {
                  roleName = this.menteeDisplay;
                  route = { name: 'participant-application-mentee-overview' };
                }
                return {
                  ...item,
                  roleName,
                  name: `${roleName} application`,
                  route
                };
              });
          }
        }
      } catch (e) {
        this.$toast(makeErrorToast("Applications list not loaded"));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }

    },
  },
};
</script>
